export const UsercentricsConfig = {
    version: 'latest',
    settingsId: {
        production: '3DQmSnVkN',
        testing: '5Nd-uscQB'
    },
    excludedPaths: ['/cookies', '/datenschutz', '/impressum']
};

export const USERCENTRICS_DATA_PROCESSING_SERVICE_TEMPLATE_ID = {
    SENTRY: 'rH1vNPCFR'
};

export const USERCENTRICS_TCF_VENDOR_ID = {
    EMETRIQ: 213
};
