import { ConsentVariant } from './ConsentVariant';
import { showCuiCookiePolicy } from './cui-cookie-policy';
import * as UsercentricsCmp from './usercentrics';
import { showUsercentricsCmp } from './usercentrics';

export const showConsentSettings = (consentVariant: ConsentVariant) => {
    switch (consentVariant) {
        case 'CUI_COOKIE_POLICY':
            return showCuiCookiePolicy();
        case 'USERCENTRICS':
            return showUsercentricsCmp();
        default:
            throw new Error(`Consent variant ${consentVariant} not supported.`);
    }
};

export const isSentryAllowed = (consentVariant: ConsentVariant) => {
    switch (consentVariant) {
        case 'CUI_COOKIE_POLICY':
            return false;
        // deactivate sentry.io (Schrems 2)
        // https://jira.congstar.net/browse/CD-266335
        // return CuiCookiePolicy.isSentryAllowed();
        case 'USERCENTRICS':
            return false;
        // deactivate sentry.io (Schrems 2)
        // https://jira.congstar.net/browse/CD-266335
        // return UsercentricsCmp.isSentryAllowed();
        default:
            throw new Error(`Consent variant ${consentVariant} not supported.`);
    }
};

export const isEmetriqAllowed = (consentVariant: ConsentVariant) => {
    switch (consentVariant) {
        case 'CUI_COOKIE_POLICY':
            console.info(`isEmetriqAllowed() not available for ${consentVariant}`);
            return false;
        case 'USERCENTRICS':
            return UsercentricsCmp.isEmetriqAllowed();
        default:
            throw new Error(`Consent variant ${consentVariant} not supported.`);
    }
};
