import { dispatchReadyEvent } from '../ConsentLoader';

export const loadCuiCookiePolicy = (version: string) => {
    // here we wait for loaded DOM because cui-cookie-policy directly interacts with document.body
    // we do not use the 'DOMContentLoaded' event because the site forum.congstar.de deletes the event and the script is not executed.
    if (document.readyState == 'loading') {
        document.addEventListener('readystatechange', function () {
            if (document.readyState === 'interactive') {
                _loadCuiCookiePolicy(version);
            }
        });
    } else {
        _loadCuiCookiePolicy(version);
    }
    dispatchReadyEvent();
};

const _loadCuiCookiePolicy = (version: string) => {
    const scriptPath = `https://cdn.congstar-congo.aoe-aws.com/libraries/@congstar/cui-cookie-policy/${version}/cui-cookie-policy/`;
    loadJS(scriptPath + 'cui-cookie-policy.esm.js', false);
    loadJS(scriptPath + 'cui-cookie-policy.js', true);
};

function loadJS(src: string, nomodule: boolean) {
    const script = document.createElement('script');
    script.src = src;
    if (nomodule) {
        script.noModule = true;
    } else {
        script.type = 'module';
    }
    document.head.appendChild(script);
}


