import * as ConsentLoader from './ConsentLoader';
import * as ConsentManagement from './ConsentManagement';
import { showConsentSettings } from './ConsentManagement';
import { getLoaderSettings } from './LoaderSettings';

export const loaderSettings = getLoaderSettings();

// Public init() function is used within forum.congstar.de as they are using RequireJS to load modules.
export const init = () => {
    ConsentLoader.loadConsent(loaderSettings);
};

export const showSettings = () => {
    showConsentSettings(loaderSettings.consentVariant);
};

export const isSentryAllowed = () => {
    return ConsentManagement.isSentryAllowed(loaderSettings.consentVariant)
};

export const isEmetriqAllowed = () => {
    return ConsentManagement.isEmetriqAllowed(loaderSettings.consentVariant)
};

export const isInitialized = () => {
    return ConsentLoader.isInitialized;
};

init();
