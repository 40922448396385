import { LoaderSettings } from './LoaderSettings';
import { loadUsercentricsCmp, UsercentricsConfig } from './usercentrics';
import { CuiCookiePolicyConfig, loadCuiCookiePolicy } from './cui-cookie-policy';
import { hasCookie } from './utils/cookie';

export const CONSENT_LOADER_SUPPRESS_COOKIE_NAME = 'CONGSTAR_CONSENT_SUPPRESS';
export const CONSENT_LOADER_EVENT_READY = 'CONGSTAR_CONSENT_READY';
export const CONSENT_LOADER_EVENT_CHANGE = 'CONGSTAR_CONSENT_CHANGE';
export let isInitialized = false;

export const loadConsent = (loaderSettings: LoaderSettings) => {
    const {consentVariant, isProduction} = loaderSettings;

    switch (consentVariant) {
        case 'CUI_COOKIE_POLICY':
            if (isConsentSuppressed()) {
                return;
            }
            return loadCuiCookiePolicy(CuiCookiePolicyConfig.version);
        case 'USERCENTRICS':
            return loadUsercentricsCmp(
                isProduction ? UsercentricsConfig.settingsId.production : UsercentricsConfig.settingsId.testing
            );
        default:
            throw new Error(`Consent variant ${consentVariant} not supported.`);
    }
};

export const isConsentSuppressed = () => {
    return hasCookie(CONSENT_LOADER_SUPPRESS_COOKIE_NAME);
};

export const dispatchReadyEvent = () => {
    window.dispatchEvent(new Event(CONSENT_LOADER_EVENT_READY));
    isInitialized = true;
};

export const dispatchChangeEvent = (originalUsercentricsEvent: Event) => {
    window.dispatchEvent(new CustomEvent(CONSENT_LOADER_EVENT_CHANGE, originalUsercentricsEvent));
}

